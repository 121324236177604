@layer components {
	.form {
		&-input {
			--size: theme('inputSize.base');
			display: flex;
			flex-grow: 1;
			position: relative;
			padding: theme('spacing.2') theme('spacing.4');
			background-color: var(--bg3);
			border: 1px solid theme('colors.gray.DEFAULT / 20%');
			outline: none !important;
			outline: none;
			font-family: var(--font-base);
			cursor: inherit;
			appearance: none;

			&:first-of-type {
				border-start-start-radius: var(--radius);
				border-end-start-radius: var(--radius);
			}

			&:last-of-type {
				border-end-end-radius: var(--radius);
				border-start-end-radius: var(--radius);
			}

			&:not(:first-of-type) {
				margin-left: -1px;
			}

			&.active,
			&:focus {
				border-color: var(--field-color);
				box-shadow: 0 0 0 1px var(--field-color);
				z-index: 2;
			}

			&:disabled {
				opacity: .8;
				background-color: var(--bg2);
				border: 1px solid theme('colors.gray.DEFAULT / 10%');
				color: theme('colors.gray.DEFAULT / 60%');

				&:placeholder {
					color: theme('colors.gray.DEFAULT / 60%');
				}
			}



			&::placeholder {
				opacity: 1;
			}

			&[type="date"]::-webkit-calendar-picker-indicator,
			&[type="time"]::-webkit-calendar-picker-indicator {
				background: none;
				pointer-events: none;
			}

			&:is(input) {
				width: 100px;
			}

			&:is(textarea) {
				min-height: 128px;
			}
		}

		&-input-start,
		&-input-end {
			display: flex;
			align-items: center;
			position: absolute;
			top: 0;
			height: 100%;
			z-index: 3;
		}

		&-input-start {
			left: 0;
		}

		&-input-end {
			right: 0;
		}

		&-checkbox {
			display: flex;
			align-items: center;
		}


		&-input-checkbox {
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 0;
			overflow: hidden;
			padding: 8px;

			background: none;
			border: none;
			box-shadow: none;
			outline: none;
			z-index: -1;
		}

		&-toggle-target {
			--cover-width: 46px;
			--cover-height: 26px;
			--padding: 2px;
			position: relative;
			width: var(--cover-width);
			min-width: var(--cover-width);
			height: var(--cover-height);
			overflow: hidden;
			background: theme('colors.gray.DEFAULT / 40%');
			border-radius: 20px;

			&:before {
				--size: 22px;

				content: '';
				display: block;

				position: absolute;
				top: calc(50% - var(--size) / 2);
				left: var(--padding);
				width: var(--size);
				height: var(--size);

				transition: .2s ease;
				background: var(--bg3);
				border-radius: 50%;

				box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
			}
		}

		&-input-checkbox:checked~&-toggle-target {
			background: var(--field-color);

			&::before {
				transform: translateX(calc(var(--cover-width) - var(--size) - var(--padding) - var(--padding)));
			}
		}

		&-checkbox-target {
			display: block;

			display: flex;
			align-items: center;
			justify-content: center;
			width: 18px;
			min-width: 18px;
			height: 18px;
			// background         : linear-gradient(180deg, #FFFFFF 0%, rgba(176, 176, 176, 0.51) 100%), #FFFFFF;
			background: var(--bg3);
			background-position: center;
			border: 1px solid theme('colors.gray.DEFAULT / 40%');
			border-radius: 4px;

			.icon {
				transform: scale(0);
				transition: .2s ease;
				font-size: 12px;
				color: transparent;
				pointer-events: none;
			}




		}

		&-input-checkbox:checked~&-checkbox-target {
			animation: check .3s forwards;

			.icon {
				transform: scale(1);
				color: var(--field-color);
			}
		}

		&-radio-target {


			display: flex;
			align-items: center;
			justify-content: center;
			width: 18px;
			min-width: 18px;
			height: 18px;
			// background         : linear-gradient(180deg, #FFFFFF 0%, rgba(176, 176, 176, 0.51) 100%), #FFFFFF;
			background: var(--bg3);
			background-position: center;
			border: 1px solid theme('colors.gray.DEFAULT / 40%');
			border-radius: 50%;



			&:before {
				content: '';
				display: block;
				width: 10px;
				height: 10px;
				margin: auto;
				opacity: 0;
				transform: scale(0);

				transition: .2s ease;
				background: var(--field-color);
				border-radius: 50%;
			}



		}

		&-input-checkbox:checked+&-radio-target {
			animation: check .3s forwards;

			&:before {
				opacity: 1;
				transform: none;
			}
		}


		&-field:hover &-checkbox-target,
		&-field:hover &-radio-target {
			@media (hover) {

				box-shadow: 0 0 0 5px theme('colors.primary.DEFAULT / 10%');
			}
		}

		&-checkbox &-input-checkbox.disabled~&-toggle-target,
		&-checkbox &-input-checkbox:disabled~&-toggle-target,
		&-checkbox &-input-checkbox.disabled~&-radio-target,
		&-checkbox &-input-checkbox:disabled~&-radio-target,
		&-checkbox &-input-checkbox.disabled~&-checkbox-target,
		&-checkbox &-input-checkbox:disabled~&-checkbox-target {
			opacity: .5;
			box-shadow: none;
			pointer-events: none;
		}
	}
}

@keyframes check {
	0% {
		box-shadow: 0 0 0 0 var(--field-color);
	}

	100% {
		box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
	}
}

.range {
	position: relative;
	cursor: pointer;


	&-inner {
		position: relative;

		position: absolute;
		top: -12px;
		left: 1px;
		width: calc(100% - 2px);
		// padding: 20px;
		// margin : -20px;
		padding-top: 12px;
		padding-bottom: 12px;
	}

	&-btn {
		display: flex;
		position: absolute;
		top: calc(50% - 11px);

		left: 0;
		width: 22px;
		height: 22px;
		transform: translateX(-50%);
		transition: .1s;
		background: var(--field-color);
		border: 3px solid var(--bg3);
		border-radius: 50%;
		box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.24);
		pointer-events: none;
	}

	&-line {
		height: 5px;
		background: var(--bg2);
		border-radius: 0px 0px var(--radius) var(--radius);
	}

	&-progress {
		position: absolute;
		top: 12px;
		left: 0;
		height: 5px;
		transition: .1s;
		background: var(--field-color);
		border-radius: 0px 0px var(--radius) var(--radius);
		pointer-events: none;
	}
}

.form-range {
	position: absolute;
	bottom: 5px;
	left: -1px;
	width: calc(100% + 2px);
}