:root {
	--bg1: #E9EAEC;
	--bg2: #F4F5F6;
	--bg3: #FFFFFF;

	--spacing: 4px;

	--radius-small: 4px;
	--radius-middle: 8px;
	--radius-large: 12px;

	--font-base: 'Inter', Arial, Helvetica, sans-serif;
	--font-alt: 'Yeseva One', Arial, Helvetica, sans-serif;

	--zi-l1: 321;
	--zi-l2: 322;
	--zi-l3: 323;
	--zi-l4: 324;
	--zi-l5: 325;
	--zi-l6: 326;
	--zi-l7: 327;
	--zi-l8: 328;
	--zi-l9: 329;
}

[data-theme="dark"] {

	--bg1: #181818;
	--bg2: #282828;
	--bg3: #323232;
}

body {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background: #FFEED2;

	* {
		font-family: var(--font-base);
	}

}

.btn {
	&-whitebg {
		box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
	}

	&-icon {
		min-width: var(--size);
	}

	&-fill {
		background: radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 73.05%), #F4A227;



		&:hover {
			background: radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 73.05%), #F4A227;
		}

		&:active {
			transform: translateY(6px);
			background: radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 73.05%), #F4A227;
		}

		&:focus:not(:active) {
			background: radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 73.05%), #F4A227;
			border: none;
			box-shadow:
				0 6px 0 #FEB548,
				0px 12px 30px rgba(255, 168, 65, 0.5);
		}

		&:not(.btn-icon) {
			box-shadow:
				0 6px 0 #FEB548,
				0px 12px 30px rgba(255, 168, 65, 0.5);

			&:hover {
				box-shadow:
					0 6px 0 #FEB548,
					0px 12px 40px rgba(255, 168, 65, 0.5);
			}

			&:active {
				box-shadow: 0 0 0 #FEB548;
			}

		}
	}

	&-orange:is(&-fill){
		background: radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 73.05%), #FF8642;

		
		&:hover {
			background: radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 73.05%), #FF8642;
		}

		&:active {
			transform: translateY(6px);
			background: radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 73.05%), #FF8642;
		}

		&:focus:not(:active) {
			background: radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 73.05%), #FF8642;
			border: none;
			box-shadow:
				0 6px 0 #FFA573,
				0px 12px 30px rgba(255, 171, 123, 0.5);
		}

		&:not(.btn-icon) {
			box-shadow:
				0 6px 0 #FFA573,
				0px 12px 30px rgba(255, 171, 123, 0.5);

			&:hover {
				box-shadow:
					0 6px 0 #FFA573,
					0px 12px 40px rgba(255, 171, 123, 0.5);
			}

			&:active {
				box-shadow: 0 0 0 #FFA573;
			}

		}
	}

	&-text {
		border-radius: 0;
	}
}

.header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding-top: 32px;
	z-index: 2;

	@media(max-width:992px) {
		padding-top: 16px;
		text-align: left;
	}
}

.started {
	display: flex;
	align-items: center;
	position: relative;
	height: 960px;
	overflow: hidden;

	@media(max-width:992px) {
		flex-direction: column;
		height: auto;
		overflow: visible;
		padding-top: 84px;
		text-align: center;
	}

	&-sheet {
		position: absolute;

		@media(max-width:1460px) {
			display: none;
		}

		&-1 {
			top: -104px;
			left: 0;
			z-index: 4;

			@media(max-width:1840px) {
				right: calc(100vw * (1920 - 300) / 1920);
				left: auto;
				width: max-content;
			}
		}

		&-2 {
			top: 120px;
			right: 0;
			z-index: 4;

			@media(max-width:1840px) {
				right: auto;
				left: calc(100vw * (1920 - 400) / 1920);
				width: max-content;
			}
		}
	}

	&-title {
		background: linear-gradient(180deg, rgba(255, 212, 144, 0.4) 0%, rgba(255, 212, 144, 0) 100%), #292929;
		-webkit-background-clip: text;
		background-clip: text;
		font-size: 60px;
		line-height: 1.25;
		-webkit-text-fill-color: transparent;
		text-fill-color: transparent;

		@media(max-width:1460px) {
			font-size: 48px;
		}

		@media(max-width:992px) {
			margin-bottom: 14px;
			font-size: 38px;
		}

		@media(max-width:theme('screens.sm')) {
			font-size: 32px;
		}
	}

	&-img,
	&-content {
		position: relative;
		z-index: 2;
	}

	&-img {
		box-shadow: 30px 30px 50px rgba(0, 0, 0, 0.4);

		@media(max-width:992px) {
			order: -1;
			width: 100%;
			max-width: 424px;
			margin-right: auto;
			margin-bottom: 40px;
			margin-left: auto;
			transform: none !important;
		}

		@media(max-width:theme('screens.sm')) {
			max-width: 300px;
		}
	}
}

.book {
	position: relative;
	overflow: hidden;
	padding-bottom: 40px;

	@media(max-width:992px) {
		display: flex;
		flex-direction: column;
		padding-top: 160px;
	}

	@media(max-width:theme('screens.sm')) {
		padding-top: 80px;
		padding-bottom: 80px;
	}

	&-clouds {
		position: absolute;
		pointer-events: none;

		&-1 {
			top: 62px;
			left: 0;

			@media(max-width:theme('screens.sm')) {
				display: none;
			}
		}

		&-2 {
			top: 0;
			right: 0;

			@media(max-width:theme('screens.sm')) {
				top: 0;
				width: 50%;
			}
		}
	}

	&-title {
		background: linear-gradient(180deg, rgba(255, 212, 144, 0.4) 0%, rgba(255, 212, 144, 0) 100%), #292929;
		-webkit-background-clip: text;
		background-clip: text;
		font-family: var(--font-alt);
		font-size: 60px;
		text-align: center;
		text-transform: uppercase;
		-webkit-text-fill-color: transparent;
		text-fill-color: transparent;

		@media(max-width:1460px) {
			font-size: 48px;
		}

		@media(max-width:992px) {
			margin-bottom: 14px;
			font-size: 38px;
		}

		@media(max-width:theme('screens.sm')) {
			font-size: 32px;
		}
	}

	&-slider {
		margin-right: auto;
		margin-left: auto;

		&-btn {
			--size: 98px;
			position: absolute;
			top: calc(50% - var(--size) / 2);

			@media(max-width:1460px) {
				--size: 60px;

				.icon {
					font-size: 18px;
				}
			}

			@media(max-width:theme('screens.sm')) {
				--size: 30px;

				.icon {
					font-size: 10px;
				}
			}
		}

		&-prev {
			right: calc(100% + 32px);

			@media(max-width:1460px) {
				right: calc(100% + 12px);
			}

			@media(max-width:1340px) {
				right: calc(100% - 2 * var(--size) / 3);
			}
		}

		&-next {
			left: calc(100% + 32px);

			@media(max-width:1460px) {
				left: calc(100% + 12px);
			}

			@media(max-width:1340px) {
				left: calc(100% - 2 * var(--size) / 3);
			}
		}
	}

	&-slider-wrapper {
		position: relative;
		width: 1160px;
		max-width: 100%;
		padding-right: calc(100% * 30 / 1160);
		padding-left: calc(100% * 30 / 1160);
		background: url("../img/book-slider.png") no-repeat center / 100% 100%;
	}

	&-page {
		position: relative;
		padding: 40px;
		background: #fff;
		// border: 1px solid #eee;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}


		&-image {
			&:not(.mobile) {
				@media(max-width:620px) {
					display: none !important;
				}
			}
		}
	}

	&-spoot {
		position: absolute;

		&-1 {
			top: -60px;
			left: -60px;

			@media(max-width:theme('screens.sm')) {
				top: -66px;
				width: 144px;
				height: 108px;
			}
		}

		&-2 {
			right: -60px;
			bottom: -80px;

			@media(max-width:theme('screens.sm')) {
				bottom: 20px;
				width: 129px;
				height: 158px;
			}
		}
	}
}

.reviews {



	&-items {
		position: relative;
		width: 100%;
		height: 560px;

		@media(max-width:1140px) {
			height: 720px;
		}

		@media(max-width:theme('screens.sm')) {
			display: flex;
			flex-wrap: wrap;
			height: auto;
			gap: 10px;
		}
	}

	&-item {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		position: absolute;

		width: 330px;
		height: 330px;
		white-space: nowrap;

		// border: 1px solid #000;

		@media(max-width:theme('screens.sm')) {
			position: relative;
			width: auto;
			height: auto;
			padding-top: 32px;
			padding-bottom: 32px;
			white-space: normal;

			br {
				display: none;
			}
		}

		@media(min-width:theme('screens.sm')) {
			&-1 {
				top: 102px;
				right: calc(50% + 262px);

				@media(max-width:1140px) {
					top: 218px;
					right: auto;
					left: 50%;
					width: 50%;
				}
			}

			&-2 {
				top: 0;
				left: calc(50% - 165px);

				@media(max-width:1140px) {
					left: 0;
					width: 100%;
				}
			}

			&-3 {
				top: 90px;
				left: calc(50% + 257px);

				@media(max-width:1140px) {
					top: 218px;
					right: 50%;
					left: auto;
					width: 50%;
				}

			}

			&-4 {
				bottom: 0;
				left: calc(50% - 123px);

				@media(max-width:1140px) {
					left: 0;
					width: 100%;
				}
			}
		}

		&-2 {
			@media(max-width:theme('screens.sm')) {
				order: -1;
				width: 100%;
			}
		}

		&-1 {
			@media(max-width:theme('screens.sm')) {
				width: calc(60% - 5px);
			}
		}

		&-3 {
			@media(max-width:theme('screens.sm')) {
				width: calc(40% - 5px);
			}
		}

		&-4 {
			@media(max-width:theme('screens.sm')) {
				width: 100%;
			}
		}

		&-spoot {
			position: absolute;
			width: max-content;

			@media(max-width:theme('screens.sm')) {
				top: 0;
				max-width: 100%;
				height: 100%;

				img {
					width: 100%;
					height: 100%;
					object-fit: scale-down;
				}
			}
		}
	}

	&-sheet {
		position: absolute;
		width: max-content;
		z-index: 2;

		&-1 {
			top: 93px;
			left: -216px;

			@media(max-width:1460px) {
				top: 10px;
				left: -53px;
			}

			@media(max-width:theme('screens.sm')) {
				top: -60px;
				width: 114px;
			}
		}

		&-2 {
			top: 36px;
			right: calc(50% + 120px);

			@media(max-width:theme('screens.sm')) {
				top: 16px;
				right: auto;
				left: 12px;
			}
		}

		&-3 {
			bottom: 132px;
			left: calc(50% + 275px);

			@media(max-width:theme('screens.sm')) {
				right: 40px;
				bottom: 150px;
				left: auto;
				width: 40px;
			}
		}

		&-4 {
			bottom: 104px;
			left: 100%;

			@media(max-width:1460px) {
				bottom: 0;
			}

			@media(max-width:theme('screens.sm')) {
				right: -20px;
				bottom: -140px;
				left: auto;
				width: 92px;
			}
		}
	}
}

.footer {
	position: relative;
	overflow: hidden;
	padding-top: 84px;

	@media(max-width:theme('screens.sm')) {
		padding-top: 20px;
	}

	&-waves {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&-title {
		background: linear-gradient(180deg, rgba(255, 212, 144, 0.4) 0%, rgba(255, 212, 144, 0) 100%), #292929;
		-webkit-background-clip: text;
		background-clip: text;
		font-family: var(--font-alt);
		font-size: 60px;
		text-align: center;
		text-transform: uppercase;
		-webkit-text-fill-color: transparent;
		text-fill-color: transparent;

		@media(max-width:1460px) {
			font-size: 48px;
		}

		@media(max-width:992px) {
			font-size: 38px;
		}

		@media(max-width:theme('screens.sm')) {
			font-size: 32px;
		}
	}

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 191px;
		background: linear-gradient(180deg, rgba(87, 185, 184, 0) 0%, #57B9B8 100%);
	}

	&-bottom {
		@media(max-width:theme('screens.lg')) {
			flex-direction: column;
		}
	}
}

.loader {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	position: fixed;
	inset: 0;
	padding-top: 114px;
	padding-right: 24px;
	padding-left: 24px;
	transition: .75s;
	background: #FFEED2;
	z-index: var(--zi-l8);

	@media(max-width:992px) {
		// padding-top: 88px;
		padding-top: 0;
	}

	.progress {
		@media (min-width:992px) {
			display: none;
		}
	}

	.started-img {
		@media(max-width:992px) {
			display: none;
		}
	}
}

body:not(.loaded) {
	overflow: hidden;
}

body.loaded .loader {
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
}