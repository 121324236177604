.grid.swiper .swiper-wrapper {
  flex-wrap      : nowrap;

}

.swiper {
  user-select: none;
}

img.swiper-lazy{
  transition: .2s ease;
}

img.swiper-lazy:not(.swiper-lazy-loaded){
  opacity: 0;
}

.swiper-button-disabled {
  opacity       : .4;
  pointer-events: none;
}

.swiper-slide {
  align-self: stretch;
  height    : auto;
}

.slider {
  position: relative;
}


.slider-prev,
.slider-next {
  position: absolute !important;
  top     : calc(50% - 30px);
  z-index : 3;

  @media(max-width:theme('screens.lg')) {
   top: calc(50% - 15px);
  }
}


.slider-next {
  left    : calc(100% + 24px);


  @media(max-width:theme('screens.lg')) {
    left: calc(100% - 24px);
  }

  @media(max-width:theme('screens.md')) {
    right: 2px;
    left: auto;
  }
}


.slider-prev {
  right   : calc(100% + 24px);

  @media(max-width:theme('screens.lg')) {
    right: calc(100% - 24px);
  }

  @media(max-width:theme('screens.md')) {
    right: auto;
    left: 2px;
  }
}

.slider--overwrap {
	@media(max-width:theme('screens.md')) {
		width        : calc(100% + 30px);
		overflow     : hidden;
		margin-right : -15px;
		margin-left  : -15px;
		padding-right: 15px;
		padding-left : 15px;
	}

	.swiper {
		@media(max-width:theme('screens.md')) {
			overflow: visible;
		}
	}
}