
.dialog {
  &-large {
    width    : var(--section-width);
    max-width: 100%;

  }

  &-small {
    width    : 340px;
    max-width: 100%;
  }

  &-middle {
    width    : 620px;
    max-width: 100%;

  }
}