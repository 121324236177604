@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @import '@qpokychuk/ripple/index.css';
    @import "@fancyapps/ui/dist/fancybox.css";
    @import 'swiper/css';
}

@import '@qpokychuk/fonts/inter/index.css';
@import '../fonts/YesevaOne/stylesheet.css';

@import '../ui/ui-core.scss';
@import '../ui/ui-example.scss';
@import '../scss/frontend--style.scss';